import { WebStorageStateStore, type UserManagerSettings } from 'oidc-client-ts';

export const settings: UserManagerSettings = {
  userStore: new WebStorageStateStore({ store: sessionStorage }),
  authority: import.meta.env.VITE_OIDC_AUTHORITY,
  client_id: import.meta.env.VITE_OIDC_CLIENT,
  redirect_uri: window.location.origin + '/callback.html',
  silent_redirect_uri: window.location.origin + '/silent-refresh.html',
  automaticSilentRenew: true,
  response_type: 'code',
  scope: 'openid',
  post_logout_redirect_uri: window.location.origin,
  filterProtocolClaims: true,
  loadUserInfo: false
};
